.cardHeaderWrapper {
  align-items: center;
  display: flex;
  gap: 16px;
}

.recordsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-block-end: 24px;
  margin-block-start: 24px;
}
