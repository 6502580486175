.mainLayout {
    padding: 48px;
}

.header {
    margin-block-end: 48px;
}

.logo {
    width: 150px;
}