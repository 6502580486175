@import "../../variables.scss";

.button {
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px 16px;
  transition: .25s;
}

.contained {
  background: $blue-50;

  &:hover {
    background: $blue-75;
  }
}

.outlined {
  border: 1px solid $grey-200;
  background: $white;

  &:hover {
    border-color: $grey-300;
  }
}
