.accountantsGrid {
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 24px;
}

.showMoreWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-block-start: 48px;
}