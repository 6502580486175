@import '../../variables.scss';

.typography {
    font-family: Roboto;
}

// Variants

.subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.bodyM {
    font-size: 16px;
    line-height: 24px;
}

.bodyS {
    font-size: 14px;
    line-height: 20px;
}

.disclaimer {
    font-size: 12px;
    line-height: 16px;
}

// Colors

.black {
    color: $black;
}

.grey-800 {
    color: $grey-800;
}

.blue-600 {
    color: $blue-600;
}
